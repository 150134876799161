// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-about-us-seo-company-toronto-jsx": () => import("./../../../src/pages/about-us-seo-company-toronto.jsx" /* webpackChunkName: "component---src-pages-about-us-seo-company-toronto-jsx" */),
  "component---src-pages-accountant-web-design-jsx": () => import("./../../../src/pages/accountant-web-design.jsx" /* webpackChunkName: "component---src-pages-accountant-web-design-jsx" */),
  "component---src-pages-contact-us-web-design-toronto-jsx": () => import("./../../../src/pages/contact-us-web-design-toronto.jsx" /* webpackChunkName: "component---src-pages-contact-us-web-design-toronto-jsx" */),
  "component---src-pages-contractor-website-design-jsx": () => import("./../../../src/pages/contractor-website-design.jsx" /* webpackChunkName: "component---src-pages-contractor-website-design-jsx" */),
  "component---src-pages-doctors-web-design-jsx": () => import("./../../../src/pages/doctors-web-design.jsx" /* webpackChunkName: "component---src-pages-doctors-web-design-jsx" */),
  "component---src-pages-financial-advisor-web-design-jsx": () => import("./../../../src/pages/financial-advisor-web-design.jsx" /* webpackChunkName: "component---src-pages-financial-advisor-web-design-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-lawyer-web-design-jsx": () => import("./../../../src/pages/lawyer-web-design.jsx" /* webpackChunkName: "component---src-pages-lawyer-web-design-jsx" */),
  "component---src-pages-painting-contractor-website-design-jsx": () => import("./../../../src/pages/painting-contractor-website-design.jsx" /* webpackChunkName: "component---src-pages-painting-contractor-website-design-jsx" */),
  "component---src-pages-portfolio-jsx": () => import("./../../../src/pages/portfolio.jsx" /* webpackChunkName: "component---src-pages-portfolio-jsx" */),
  "component---src-pages-seo-company-toronto-jsx": () => import("./../../../src/pages/seo-company-toronto.jsx" /* webpackChunkName: "component---src-pages-seo-company-toronto-jsx" */),
  "component---src-pages-seo-consultation-jsx": () => import("./../../../src/pages/seo-consultation.jsx" /* webpackChunkName: "component---src-pages-seo-consultation-jsx" */),
  "component---src-pages-thank-you-jsx": () => import("./../../../src/pages/thank-you.jsx" /* webpackChunkName: "component---src-pages-thank-you-jsx" */),
  "component---src-pages-toronto-web-design-jsx": () => import("./../../../src/pages/toronto-web-design.jsx" /* webpackChunkName: "component---src-pages-toronto-web-design-jsx" */),
  "component---src-pages-vancouver-seo-jsx": () => import("./../../../src/pages/vancouver-seo.jsx" /* webpackChunkName: "component---src-pages-vancouver-seo-jsx" */),
  "component---src-pages-web-designer-toronto-jsx": () => import("./../../../src/pages/web-designer-toronto.jsx" /* webpackChunkName: "component---src-pages-web-designer-toronto-jsx" */),
  "component---src-pages-website-design-for-electrical-contractors-jsx": () => import("./../../../src/pages/website-design-for-electrical-contractors.jsx" /* webpackChunkName: "component---src-pages-website-design-for-electrical-contractors-jsx" */)
}

